<template>  

    <b-row class="table-body">
      <b-col>
        
        <ReservationsTableWidget
        
        ></ReservationsTableWidget>
      </b-col>
    </b-row>
  

</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ReservationsTableWidget from "@/view/pages/business/components/ReservationsTableWidget.vue";
import { BUSINESS_RESERVATIONS } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

export default {
  name: "Reservations",
  computed: {
    ...mapGetters(["currentUser", "getDate"]),
  },
  components: {
    ReservationsTableWidget,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Orders" },
      { title: "All Bookings" },
    ]);

    // look for current user
  
  },

  data() {
    return {
      form: {
        reservations: [],
      },
    };
  },
  methods: {
    fetchReservations() {
      this.form.reservations = []
      this.$store
        .dispatch(BUSINESS_RESERVATIONS)
        // go to which page after successfully login
        .then((response) => {
          this.form.reservations = response
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
 
};
</script>
<style scoped>
.table-body{
  margin-top:0px !important;
}
</style>
