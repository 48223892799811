<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="card card-custom card-stretch gutter-b">
          <!-- Header -->
          <v-card-title class="m-5">
            <h3 class="font-weight-bolder text-primary">Table Reservationsss</h3>
          </v-card-title>

          <!-- Data Table -->
          <v-card-subtitle>
            <v-data-table
              :headers="headers"
              :items="form.reservations.data"
              item-key="id"
              class="elevation-1"
              :items-per-page="itemsPerPage"
              :page="currentPage"
              :footer-props="{'items-per-page-options': []}"
              hide-default-footer
            >
              <!-- Desktop View -->
              <template v-slot:body="{ items }" v-if="!isMobileView">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td v-for="header in headers" :key="header.value">
                      {{ item[header.value] }}
                    </td>
                    <td>
                      <span class="d-flex">
                        <template v-if="item.status === 'Pending'">
                          <v-btn @click="openConfirmationDialog(item.id, 'Reserved')" class="mr-1 bg-primary text-light" small>Accept</v-btn>
                          <v-btn @click="openConfirmationDialog(item.id, 'Rejected')" class="bg-danger text-light" small>Decline</v-btn>
                        </template>
                        <template v-else>
                          <v-btn :class="item.status === 'Reserved' ? 'bg-primary text-light' : 'bg-danger text-light'" small>
                            {{ item.status }}
                          </v-btn>
                        </template>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>

              <!-- Mobile View -->
          <!-- Mobile View -->
<template v-slot:item="{ item }" v-else>
  <v-row class="mb-2" no-gutters>
    <v-col cols="12" class="d-flex flex-column border-bottom py-2">
      <!-- Dynamically iterate over headers and display corresponding item data -->
      <v-row v-for="(header, index) in headers" :key="index">
        <v-col cols="6" class="font-weight-bold">{{ header.text }}:</v-col>
        <v-col cols="6">{{ item[header.value] }}</v-col>
      </v-row>

      <!-- Add action buttons -->
      <v-row>
        <v-col cols="12" class="d-flex">
          <span class="d-flex">
            <template v-if="item.status === 'Pending'">
              <v-btn @click="openConfirmationDialog(item.id, 'Reserved')" class="mr-1 bg-primary text-light" small>Accept</v-btn>
              <v-btn @click="openConfirmationDialog(item.id, 'Rejected')" class="bg-danger text-light" small>Decline</v-btn>
            </template>
            <template v-else>
              <v-btn :class="item.status === 'Reserved' ? 'bg-primary text-light' : 'bg-danger text-light'" small>
                {{ item.status }}
              </v-btn>
            </template>
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>


            </v-data-table>
          </v-card-subtitle>

          <!-- Pagination Controls -->
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="loadReservations"
          ></v-pagination>

          <!-- Confirmation Dialog -->
          <v-dialog v-model="dialog.visible" max-width="400">
            <v-card>
              <v-card-title class="headline">Confirm Action</v-card-title>
              <v-card-subtitle>
                Are you sure you want to change the status to "{{ dialog.status }}"?
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="confirmChangeStatus" class="bg-primary text-light font-poppins">Yes</v-btn>
                <v-btn @click="dialog.visible = false" text>No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BUSINESS_RESERVATIONS_UPDATE, BUSINESS_RESERVATIONS } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

export default {
  name: "ReservationsTableWidget",
  computed: {
    ...mapGetters(["currentUser"]),
    totalPages() {
      return Math.ceil(this.form.reservations.total / this.itemsPerPage);
    }
  },
  data() {
    return {
      isMobileView: window.innerWidth <= 600, // Initialize based on current screen width
      form: {
        reservations: {
          data: [],
          current_page: 1,
          last_page: 1,
          total: 0
        },
      },
      headers: [
        { text: 'Booking ID', value: 'id', sortable: false },
        { text: 'First Name', value: 'first_name', sortable: false },
        { text: 'Last Name', value: 'last_name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Contact', value: 'phone', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Number Of People', value: 'number_people', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Instructions', value: 'instructions', sortable: false },
        // { text: 'Actions', value: 'actions', sortable: false }
      ],
      dialog: {
        visible: false,
        id: null,
        status: ''
      },
      currentPage: 1,
      itemsPerPage: 10,
      loading: false
    };
  },
  watch: {
    currentPage(newPage) {
      this.loadReservations(newPage);
    }
  },
  mounted() {
    this.loadReservations();
  },
  methods: {
    loadReservations(page = 1) {
      this.loading = true;
      this.$store
        .dispatch(BUSINESS_RESERVATIONS, { page, per_page: this.itemsPerPage })
        .then((response) => {
          this.form.reservations = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    openConfirmationDialog(id, status) {
      this.dialog.visible = true;
      this.dialog.id = id;
      this.dialog.status = status;
    },
    confirmChangeStatus() {
      const { id, status } = this.dialog;
      this.dialog.visible = false;

      const data = {
        status: status,
        id: id
      };

      this.$store
        .dispatch(BUSINESS_RESERVATIONS_UPDATE, data)
        .then(() => {
          this.loadReservations(this.currentPage); // Refresh reservations on the same page
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>

</style>
